// if you need to add custom js put it in here
$(window).load(function () {
    $('.HButton').bootstrapSwitch();
    $('.about-content-wrapper').matchHeight();
    $('.blogTitle').matchHeight();
    $('.blogDescription').matchHeight();
    $('.pf-recent-related-product-item.product-match-height').matchHeight();
    if ($('.w-slider:visible').length > 0) {
        InitBannerSlider();
    } 
});
$(function () {
    $('.nav-menu.w-nav-menu .w-dropdown').append($('<div class="nav-dropdown-strip">'));
    $(window).on('resize', SetNavDropdownMaxHeight);
    $(document).on('click', '.menu-button', SetNavDropdownMaxHeight);
    $(document).on('click', '.nav-link.nav-dropdown', SetNavDropdownMaxHeight);
    $(document).on('click', '.bag-opener.w-nav-link', SetNavDropdownMaxHeight);
    $('#bag-opener').click(function () {
        SetNavDropdownMaxHeight();
    });   
    $('.nav-menu.w-nav-menu .w-dropdown').hover(function (e) {
        e.preventDefault();
        if ($(window).width() < 768)
            return;

        var openClass = 'w--open';
        if (e.type === 'mouseenter') {
            $(this).find('.w-dropdown-toggle').addClass(openClass);
            $(this).find('.w-dropdown-list').addClass(openClass);;
            $(this).find('.w-dropdown-toggle').find('.fa-angle-down').addClass('fa-angle-up');
            $(this).find('.w-dropdown-toggle').find('.fa-angle-up').removeClass('fa-angle-down');
            $(this).find('.nav-dropdown-strip').addClass(openClass);
        } else if (e.type === 'mouseleave') {
            $(this).find('.w-dropdown-toggle').removeClass(openClass);
            $(this).find('.w-dropdown-list').removeClass(openClass);
            $(this).find('.w-dropdown-toggle').find('.fa-angle-up').addClass('fa-angle-down');
            $(this).find('.w-dropdown-toggle').find('.fa-angle-down').removeClass('fa-angle-up');
            $(this).find('.nav-dropdown-strip').removeClass(openClass);
        }
    });
    $(window).on('resize', InitBannerSlider);
    $('.searchform-clear + input[type="search"]').on('input', function () {
        var $clearButton = $(this).prev('.searchform-clear');
        if ($(this).val() !== '')
            $clearButton.show();
        else
            $clearButton.hide();
    });
    $('.searchform-clear').on('click', function () {
        $(this).next('input[type="search"]').val('');
        $(this).hide();
    });
    $(document).on("scroll", function () {
        if ($(document).scrollTop() > 100) {
            $("header").addClass("shrink");
        }
        else {
            $("header").removeClass("shrink");
        }
    });
    //$('#prod-search-tbx').focus(function () {
    //    $('#prod-search-btn').css('box-shadow', '-1px -1px 0px 0px black, -1px 1px 0px 0px black');
    //});
    //$('#prod-search-tbx').blur(function () {
    //    $('#prod-search-btn').css('box-shadow', 'none');
    //});
    var enquireModal = $('[data-remodal-id=enquire-modal]').remodal();
    $(document).on('click','.pf-enquire-product',function () {
        //Product List
        if ($(this).parent('.pf-list-view-enquire-wrapper').length) {
            var prodInfoWrapper = $(this).parent().parent();
            var name = prodInfoWrapper.find('.pf-product-name').text();
            var code = prodInfoWrapper.find('.pf-product-code').text();
            $('.enquire-product-title').text(name);
            $('#prodname').val(name);
            $('#prodcode').val(code);
        }
        //Variant
        if ($(this).closest('.pf-product-variant-item').length) {
            var prodInfoWrapper = $(this).closest('.pf-product-variant-item');
            var name = prodInfoWrapper.children('[itemprop="name"]').attr('content');
            var code = prodInfoWrapper.find('[itemprop="sku"]').attr('content');
            $('.enquire-product-title').text(name);
            $('#prodname').val(name);
            $('#prodcode').val(code);
        }
        appendScript('https://www.google.com/recaptcha/api.js');
        enquireModal.open();
    });
    function appendScript(filepath) {
        if ($('head script[src="' + filepath + '"]').length > 0)
            return;

        var ele = document.createElement('script');
        ele.setAttribute("type", "text/javascript");
        ele.setAttribute("src", filepath);
        $('head').append(ele);
    }
    $('.mega-list.w-dropdown-list > .ecs-row').each(function () {
        $(this).height(Math.max($(this).find('.pf-nav-links').height(), $(this).find('.pf-nav-images').height()));
    });
    $(document).on('deliveryAndTotalsLoaded.checkout.platform', function (event, data) {
        SetPayPalMessageAmountOnCheckout();
    });
});
function SetNavDropdownMaxHeight() {
    if ($(window).width() < 768) {
        $('.w-nav-overlay').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.topheader').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.mega-list').css('max-height', '100%');
    } else {
        $('.mega-list').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.topheader').css('max-height', '400px');
        $('.topheader').css('overflow', 'auto');
        $('.w-nav-overlay').css('max-height', '100%');
    }
}
function InitBannerSlider() {
    var $visibleBanner = $('.w-slider:visible');
    if (!$visibleBanner.data('initialised')) {
        Webflow.require('slider').redraw();
        $visibleBanner.data('initialised', true);
    }
}
function SetPayPalMessageAmountOnCheckout()
{
        var amount = $('#orderFullTotal').data('order-full-total');
        $('.paypal-message-checkout').attr('data-pp-amount', amount);
}
//To be turned on if useful: 


////// compact header when opening and closing the expanded search functionality. 
//$('.search-opener').click(function () {
//    $(window).scrollTop(0);
//    $('#search-div-block-compact').show();
//    $('.logo-section').hide();
//});
//$('.search-close').click(function () {
//    if (document.body.scrollTop < 50 || document.documentElement.scrollTop < 50) {
//        $('#search-div-block-compact').hide();
//        $('.logo-section').show();
//    }
//});


///// Hide promotional strip when using mobile nav. 
//$('.hamburger-menu-button').click(function () {
//    console.log($(this).is('.w--open'));
//    if ($(this).is('.w--open')) {
//        $('.pf-promotion-container').hide()
//    } else {
//        $('.pf-promotion-container').show()
//    }
//});

////// CoachHouse's compact header functionality
//window.onscroll = function () { scrollFunction() };
//function scrollFunction() {
//    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
//        $('#search-div-block-compact').show();
//        $('.logo-section').hide();
//    } else {
//        if (!$('.search-section').is(':visible')) {
//            $('#search-div-block-compact').hide();
//            $('.logo-section').show();
//        }
//    }
//}


/////// additional mac/ios specific styling required. add the class mac-os to the required element
//if (navigator.userAgent.indexOf('Mac') > 0) {
//    $('.pf-new-product-tag').each(function () {
//        $(this).find('span').addClass('mac-os')
//    });
//    $('.pf-sale-product-tag').each(function () {
//        $(this).find('span').addClass('mac-os')
//    });
//}